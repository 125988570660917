import axios from "axios";
import _ from "lodash";

export class API {
  static headers() {
    return {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
  }

  static request(options) {
    options.headers = _.merge(this.headers(), options.headers);

    const request = axios(options)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response) {
          throw error.response.data;
        } else if (error.request) {
          // console.log("Request", error.request);
          alert("Request Error", "Something went wrong");
        } else {
          alert("Network Error", error.message);
        }
      });

    return request;
  }
}
