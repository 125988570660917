import { Box, Button, Divider } from "@mui/material";
import { InputController } from "../ReusableComponents/InputBox";
import { SelectConvenienceFeeType } from "../ReusableComponents/SelectConvenienceFeeType";
import { SelectProvider } from "../ReusableComponents/SelectProvider";
import { useNavigate } from "react-router-dom";
import { NonRequiredInput } from "../ReusableComponents/NonRequiredInput";
import { useWatch } from "react-hook-form";

const MerchantInformation = ({
  control,
  errors,
  handleSubmit,
  onSubmit,
  editMode,
  getMerchantAuthenticationResponse,
}) => {
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate("/manage/merchant");
  };
  const selectedProvider = useWatch({
    control,
    name: "provider",
  });
  return (
    <div className="flex flex-col gap-4 justify-center">
      {editMode && (
        <div className="flex gap-2 p-2">
          <span className="text-[#757582] w-[30%] text-[15px]">Merchant Id: </span>
          <div className="text-[15px] w-[100%]">{getMerchantAuthenticationResponse?.merchant_authentication_id}</div>
        </div>
      )}
      {editMode && (
        <InputController
          control={control}
          errors={errors.name}
          name="name"
          label={"Name"}
          type={"text"}
          width={"w-full"}
          size="small"
        />
      )}
      <SelectProvider control={control} errors={errors.provider} name="provider" label={"Provider"} />
      <InputController
        control={control}
        errors={errors.clientPublicKey}
        name="clientPublicKey"
        label={"Client Public Key"}
        type={"text"}
        width={"w-full"}
        size="small"
      />
      <NonRequiredInput
        control={control}
        errors={errors.privateKey}
        name="privateKey"
        label={"Private Key"}
        type={"text"}
        width={"w-full"}
        size="small"
      />
      {selectedProvider === "authorize_net" && (
        <>
          <div className="font-medium text-black mt-4">Google Pay</div>
          <NonRequiredInput
            control={control}
            errors={errors.payment_gateway_id}
            name="paymentGatewayId"
            label={"Payment Gateway Id"}
            type={"text"}
            width={"w-full"}
            size="small"
            className="mb-3"
          />
          <NonRequiredInput
            control={control}
            errors={errors.google_pay?.merchant_id}
            name="merchantId"
            label={"Merchant Id"}
            type={"text"}
            width={"w-full"}
            size="small"
            className="mb-3"
          />
          <NonRequiredInput
            control={control}
            errors={errors.google_pay?.merchant_name}
            name="merchantName"
            label={"Merchant Name"}
            type={"text"}
            width={"w-full"}
            size="small"
            className="mb-3"
          />
          <NonRequiredInput
            control={control}
            errors={errors.google_pay?.payment_profile_id}
            name="paymentProfileId"
            label={"Payment Profile ID"}
            type={"text"}
            width={"w-full"}
            size="small"
            className="mb-3"
          />
        </>
      )
      }
      < div className="w-[calc(100%+120px)] ml-[-60px]">
        <Divider sx={{ borderBottomWidth: "1px", borderColor: "grey.300" }}></Divider>
      </div>{" "}
      <div>Processing Fee</div>
      <NonRequiredInput
        control={control}
        errors={errors.processingFeeFlat}
        name="processingFeeFlat"
        label={"Processing Fee Flat"}
        type={"text"}
        width={"w-full"}
        size="small"
      />
      <NonRequiredInput
        control={control}
        errors={errors.processingFeePercentage}
        name="processingFeePercentage"
        label={"Processing Fee Percentage"}
        type={"text"}
        width={"w-full"}
        size="small"
      />
      <div className="w-[calc(100%+120px)] ml-[-60px]">
        <Divider sx={{ borderBottomWidth: "1px", borderColor: "grey.300" }}></Divider>
      </div>{" "}
      <div>Aravali Convenience Fee</div>
      <NonRequiredInput
        control={control}
        errors={errors.convenienceFeeFlat}
        name="convenienceFeeFlat"
        label={"Convenience Fee Flat"}
        type={"text"}
        width={"w-full"}
        size="small"
      />
      <NonRequiredInput
        control={control}
        errors={errors.convenienceFeeFlatMax}
        name="convenienceFeeFlatMax"
        label={"Convenience Fee Flat Max"}
        type={"text"}
        width={"w-full"}
        size="small"
      />
      <NonRequiredInput
        control={control}
        errors={errors.convenienceFeeFlatMin}
        name="convenienceFeeFlatMin"
        label={"Convenience Fee Flat Min"}
        type={"text"}
        width={"w-full"}
        size="small"
      />
      <NonRequiredInput
        control={control}
        errors={errors.convenienceFeePercentage}
        name="convenienceFeePercentage"
        label={"Convenience Fee Percentage"}
        type={"text"}
        width={"w-full"}
        size="small"
      />
      <SelectConvenienceFeeType
        control={control}
        errors={errors.convenienceFeeType}
        name="convenienceFeeType"
        label={"Convenience Fee Type"}
      />
      <div className="flex items-center justify-center">
        <Box className="flex flex-row justify-center mx-auto gap-2 mt-3 w-[180px]">
          <Button
            onClick={handleCancel}
            variant="contained"
            className="mx-auto w-[200px] text-lg text-white mt-4 normal-case"
          >
            <span>Cancel</span>
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            className="mx-auto w-[200px] text-lg text-white mt-4"
          >
            <span>Submit</span>
          </Button>
        </Box>
      </div>
    </div >
  );
};

export default MerchantInformation;
